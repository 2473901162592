.bingo-container {
  margin-top: 2vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  max-width: 600px;
  width: 100%;
}

.bingo-button {
  flex: 1 1 auto;
  min-width: 65px;
  height: auto;
  aspect-ratio: 1 / 1;
  border: 2px solid black;
  background-color: transparent;
  cursor: pointer;
  font-size: 0.4rem;
  margin: 5px;
  transition: background-color 0.3s, transform 0.1s;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5%;
}

.bingo-button:hover {
  background-color: #f0f0f0;
}

.bingo-button:active {
  transform: scale(0.98);
}

.bingo-button.selected {
  background-color: green;
  color: white; 
}

@media (max-width: 600px) {
  .bingo-container {
    justify-content: space-between;
    padding: 0;
    max-width: 100%;
  }
  .bingo-button {
    min-width: 30%;
    font-size: 2.3vw; 
    padding: 1px; 
    margin: 1px;
  }
}

@media (min-width: 601px) {
  .bingo-container {
    justify-content: center;
    margin: auto;
  }
  .bingo-button {
    min-width: 20%;
    font-size: 14px;
  }
}